<template>
	<iframe id="dashboard" :src="src" :height="height + '%'" :width="width + '%'"></iframe>
</template>

<script>
/**
 * @displayName w-iframe
 *
 */
export default {
	name: 'WIframe',
	components: {},
	mixins: [],
	props: {
		src: {
			type: String,
			required: true
		},
		width: {
			type: Number,
			default: 100
		},
		height: {
			type: Number,
			default: 100
		}
	},
	data: function () {
		return {
		}
	},
	computed: {
	},
	watch: {},
	created: function () {
	},
	mounted: function () {
	},
	destroyed: function () {
	},
	methods: {}
}
</script>
